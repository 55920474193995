.fieldset + .fieldset {
  margin-top: var(--spacing-07);
}

.fieldsetTitle {
  border-bottom: 1px solid var(--color-gray-100);
  font: var(--typography-headline-06);
  padding: var(--spacing-06);
}

.fieldsetTitleNumber {
  color: var(--theme-accent-color);
}

.fieldsetContent {
  padding: var(--spacing-06);
}

.fieldsetDescription {
  margin-bottom: var(--spacing-07);
}

.fieldsetChildren {
  display: grid;
  gap: var(--spacing-07) var(--spacing-06);
  grid-template-columns: 1fr;
}

.fieldsetEmptyField {
  display: none;
}

.actions {
  border-top: 1px solid var(--color-gray-100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04);
  padding: var(--spacing-05) var(--spacing-05);
  text-align: center;
}

.actions > * {
  width: 100%;
}

@media (--media-m) {
  .fieldsetChildren {
    grid-template-columns: 1fr 1fr;
  }

  .fieldsetOneColumn .fieldsetChildren {
    grid-template-columns: 1fr;
  }

  .fieldsetEmptyField {
    display: block;
  }
}
