.content {
  --section-vertical-spacing: var(--spacing-07);

  flex: 1;
}

.help {
  font: var(--typography-paragraph-s);
  text-align: center;
}

.separator {
  border: none;
  border-top: 1px solid var(--color-gray-100);
  margin: var(--spacing-05) 0;
}

.kioskMobileOnboarding {
  font: var(--typography-paragraph-s);
  margin-top: var(--spacing-06);
  padding: var(--spacing-05);
}

.kioskMobileOnboardingContent {
  display: flex;
  gap: var(--spacing-05);
}

.kioskMobileOnboardingQR {
  flex: 0 0 30%;
}

.kioskMobileOnboardingTitle {
  font: var(--typography-headline-06);
  margin-bottom: var(--spacing-01);
  text-align: center;
}

@media (--media-m) {
  .content {
    --section-vertical-spacing: 80px;
  }

  .formContent {
    grid-column: 4/10;
  }
}

@media (--media-xl) {
  .formContent {
    grid-column: 5/9;
  }
}
